
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type HotelsService from '@/modules/hotels/hotels.service';
import type UserService from '@/modules/user/user.service';
import USER_LEVELS from '../../user/constants/user-levels.constant';

@Component
export default class HomeHotelTitle extends Vue {
    @inject(KEY.UserService) protected userService!: UserService;
    @inject(KEY.HotelsService) protected hotelsService!: HotelsService;

    get name(): string | null {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        if (this.userService.viewAs === USER_LEVELS.CHAIN || this.userService.viewAs === USER_LEVELS.CLUSTER) {
            return this.userService.levelName;
        }

        return this.hotelsService.hotelNames[currentHotelId];
    }
}
