
import LoadingModel from '@/modules/common/models/loading.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import SCORE_THRESHOLDS from '@/modules/score/constants/score-thresholds.constant';
import ScoreTooltipWrapper from '@/modules/score/components/score-tooltip-wrapper.vue';

@Component({
    components: {
        ScoreTooltipWrapper,
        LoaderWrapper,
    },
})
export default class CiScore extends Vue {
    @Prop({ })
    loading!: LoadingModel;

    @Prop({ required: false, type: Number, default: null })
    score!: number | null;

    @Prop({ required: false, type: Number, default: null })
    scoreDif!: number | null;

    @Prop({ type: Boolean })
    skeleton!: boolean;

    thresholdColors = ['#52C161', '#8CD446', '#FFD205', '#FF9E21', '#EA7877'];

    get assessment() {
        return this.scoreAssessment && this.scoreAssessment.toLowerCase();
    }

    get scoreAssessment() {
        if (!this.score) {
            return null;
        }

        let assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.CRITICAL];

        if (this.score > SCORE_THRESHOLDS.GOOD) {
            assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.EXCELLENT];
        } else if (this.score > SCORE_THRESHOLDS.FAIR) {
            assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.GOOD];
        } else if (this.score > SCORE_THRESHOLDS.POOR) {
            assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.FAIR];
        } else if (this.score > SCORE_THRESHOLDS.CRITICAL) {
            assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.POOR];
        }

        return assessment;
    }

    get assessmentColor() {
        if (!this.scoreAssessment) {
            return null;
        }

        const threshold = this.scoreAssessment;
        const thresholdEntries = Object.entries(SCORE_THRESHOLDS);
        const colorIndex = thresholdEntries.slice(0, thresholdEntries.length / 2).reverse().findIndex(([_, value]) => threshold === value);

        if (colorIndex === -1) {
            return null;
        }

        return this.thresholdColors[colorIndex];
    }

    displayScoreDif() {
        if (this.scoreDif === null) {
            return null;
        }
        return `(${this.scoreDif > 0 ? '+' : ''}${this.scoreDif})`;
    }
}
