
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ScoreGraphLabels extends Vue {
    @Prop({ required: true })
    labelMinMax!: string;

    @Prop({ type: Boolean })
    skeleton!: boolean;
}
