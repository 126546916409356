
import { $enum } from 'ts-enum-util';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import COMPARED_TO_SETTINGS from '@/modules/score/constants/compared-to.settings.constant';
import ScoreService, { ScoreServiceS } from '@/modules/score/score.service';
// @ts-ignore
import CustomDesignSelect, { Item } from '@/modules/common/components/ui-kit/custom-design-select.vue';
import { SettingsHotelService } from '@/modules/settings/settings-hotel.service';

@Component({
    components: { CustomDesignSelect },
})
export default class ComparedScoreDocumentFilter extends Vue {
    @inject(KEY.SettingsHotelService) private settingsHotelService!: SettingsHotelService;
    @inject(ScoreServiceS) private scoreService!: ScoreService;

    get comparedTo(): COMPARED_TO_SETTINGS {
        return this.settingsHotelService.scoreComparedTo;
    }

    set comparedTo(value: COMPARED_TO_SETTINGS) {
        this.settingsHotelService.scoreComparedTo = value;
        this.scoreService.switchComparedToGraphs(value);
    }

    get comparedToItems(): Item[] {
        return $enum(COMPARED_TO_SETTINGS).map((value): Item => ({
            value,
            name: this.$t(`score.comparedToSetting.${value}`) as string,
        })).filter(item => item.value !== COMPARED_TO_SETTINGS.MARKET);
    }
}
